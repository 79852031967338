import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Jumbotron from "../components/jumbotron"
import { Link } from "gatsby"
import Bee from "../components/Bee"
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Jumbotron />
    <Container className="mb-4">
      <h1 className="text-primary welcome">Welcome to Waite Psychology</h1>
      <p>We are a family run educational psychology service who are passionate about supporting children and young people to grow, learn and achieve their potential.</p>
      <p>We apply psychological knowledge, advice and evidence-based interventions to find creative solutions that remove barriers to learning across all areas of education at the whole school, group, individual child or young person level.</p>
      <p>The key to our work and success is our <span className="text-primary">RELATIONSHIPS</span> with schools and the children, their families and the communities they serve. Our approach is consultative, underpinned by a focus on collaboration and solution-focused practice. We work with staff to develop a shared understanding of problematic situations, to then identify outcomes jointly and problem solve the best ways of achieving those outcomes. Wherever possible, we encourage collaborative working with parents and ensure our outcomes are informed by the child’s or young person’s voice.</p>
      <p>We work across the North West region, including Blackburn with Darwin, Blackpool, Bolton, Cumbria (South Lakeland district) and Lancashire.</p>
      <div className="text-center">
        <div className="container position-relative pt-3">
          <Bee/>
          <Button variant="secondary" size="lg">
            <Link to="contact" className="text-white">
              Let's Chat
            </Link>
          </Button>
        </div>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
