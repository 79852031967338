import React from "react"
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
import Carousel from 'react-bootstrap/Carousel'

const Jumbotron = () => (
  <StaticQuery
    query={graphql`
      query {
        spring: file(relativePath: { eq: "Spring.png" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 945) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        summer: file(relativePath: { eq: "Summer.png" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 945) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        autumn: file(relativePath: { eq: "Autumn.png" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 945) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        winter: file(relativePath: { eq: "Winter.png" }) {
          childImageSharp {
            fluid(quality: 100, maxHeight: 945) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const spring = data.spring.childImageSharp.fluid
      const summer = data.summer.childImageSharp.fluid
      const autumn = data.autumn.childImageSharp.fluid
      const winter = data.winter.childImageSharp.fluid
      return (
        <Carousel>
          <Carousel.Item>
            <Img fluid={spring} />
          </Carousel.Item>
          <Carousel.Item>
            <Img fluid={summer} />
          </Carousel.Item>
          <Carousel.Item>
            <Img fluid={autumn} />
          </Carousel.Item>
          <Carousel.Item>
            <Img fluid={winter} />
          </Carousel.Item>
        </Carousel>
      )
    }}
  />
)

export default Jumbotron
